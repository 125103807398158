import React, {Component} from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

import Layout from '../components/layout'
import Ionicon from 'react-ionicons'

export default class menuButton extends Component {
  static propTypes = {
    action: PropTypes.func.isRequired
  }



  render(){
    const {action} = this.props;
    return <button className="menuButton" onClick={action} ><Ionicon icon ="ios-menu" color="black"/></button>
  }
}
