import React, {Component} from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

import Particles from 'react-particles-js';

const particleOptions = {
  particles:{
    number: {
       value: 20,
       density: {
         enable: true,
         value_area: 800
       }
     },
     line_linked: {
      enable: true,
      distance: 100,
      color: "#F7F7F7",
      opacity: 0.05 ,
      width: 1
    }
  },
  size: {
     value: 8,
     "random": true,
   },
  interactivity: {
    detect_on:'window',
    events:{
      onhover:{
        enable:true,
        mode:'grab'
      },
      onclick: {
        enable: true,
        mode: "repulse"
      }
    },
    modes:{
      grab: {
        distance: 200,
        line_linked: {
        opacity: 0.6
        }
      },
      "repulse": {
        "distance": 200,
        "duration": 0.4
      }
    }
  }
}

export default class CustomParticles extends Component {

  render(){
        return (
          <div id="particles">
            <Particles
              params={particleOptions}
            /></div>
        );
    };
}
